<script setup lang="ts">
import TitleInGrid from "~/components/Redmonkey/Base/TitleInGrid.vue";
import Item from "~/components/Redmonkey/Courses/Item.vue";

const props = defineProps({
  courses: {
    type: Object,
    required: true,
    default: {nodes: []}
  },
  title: {
    type: String,
    required: true,
    default: ''
  },
  button: {
    type: Object,
    default: {
      component: 'a',
      url: null,
      title: null,
      target: null
    }
  }
})

const items = [
  {
    id: 'title',
    component: TitleInGrid
  },
  ...props.courses?.nodes
]

const isButton = () => {
  return props?.button?.url;
}
</script>
<template>
  <div class="courses-list courses-short">
    <div class="decorate-star">
      <img src="/images/star-clip-blue.png" :alt="title"/>
    </div>
    <div class="courses-items flex wrap">
      <template v-for="(course, index) in items" :key="course.id">
        <component v-if="course.component" :is="course.component" :title="title" />
        <Item
            v-else-if="index <= 5"
            :title="course.title"
            :content="course.courses.smallDescription"
            :age="course.courses.age"
            :price="course.courses.price"
            :url="course.uri"
        />
      </template>
    </div>
    <div class="all-courses-button" v-if="isButton() && items.length > 5">
      <component class="action primary" :is="button.component" :to="button.url" :href="button.url" :target="button.target">
        {{ button.title}}
      </component>
    </div>
  </div>
</template>